/* eslint-disable import/prefer-default-export */

export const CONTENT_TYPES = {
  RECIPE: 'recipe',
  SECTION: 'section',
  REFERENCE: 'reference',
  BOOK: 'book',
  PERSON: 'person',
  SEARCH: 'search',
  CONTENT: 'content',
  COLLECTION: 'collection',
};

export const BREAKPOINTS = {
  MOBILE: 0,
  PHONE_VERTICAL: 480,
  PHONE_HORIZONTAL: 640,
  TABLET_VERTICAL: 768,
  TABLET_HORIZONTAL: 960,
  DESKTOP: 1024,
};

export const COLOURS = {
  ALABASTER: '#f8f8f8',
  DARK_ALABASTER: '#2a2c2f',
  BLACK: '#000000',
  CARDINAL: '#cb1f27',
  DUSTY_GREY: '#959595',
  ENERGIZED: '#fa6422',
  GREEN: '#008000',
  MINE_SHAFT: '#333333',
  DARK_MINE_SHAFT: '#cccccc',
  ROYAL: '#4867aa',
  SOLID_GREY: '#8e8d8d',
  STABLE: '#bfbfbf',
  WHITE: '#fff',
  ERROR: '#d32f2f',
};

export const BOOK_TOP_LEVEL_CATEGORIES = [
  'alacarte',
  'az',
  'mytopbooks',
  'popular',
  'published-old',
  'published',
  'recentlyadded',
  'recommended',
];

export const TRUNCATION_LIMIT = {
  MOBILE: 300,
  TABLET: 600,
  DESKTOP: 900,
};

export const COMMENT_RULES = {
  review: {
    minLength: 30,
    maxLength: 5000,
  },
  question: {
    minLength: 0,
    maxLength: 250,
  },
  note: {
    minLength: 0,
    maxLength: 5000,
  },
  response: {
    minLength: 0,
    maxLength: 2500,
  },
};

export const EDITOR_FOCUSED_CLASS_PREFIX = 'editor-focused';

export const COLLECTION_VISIBILITY_OPTIONS = [
  { value: 'public', label: 'Public: Anyone' },
  { value: 'unlisted', label: 'Unlisted: People you share the link with' },
  { value: 'private', label: 'Private: Only you' },
];

export const EZOIC_INFINITE_SCROLL_PLACEMENT_IDS = [106, 112, 113];

// Marketing video
export const MARKETING_VIDEO_ID = '259262071';
export const MARKETING_VIDEO_SERVICE = 'vimeo';
export const MARKETING_NUMBER_OF_RECIPES = '150,000';

// Don't use this to construct urls, but it is useful to test which site we are in.
export const LIVE_SITE_URL = 'https://app.ckbk.com';

export const TOOLTIP_DELAY_MS = 750;

export const BASE_FONT_SIZE = 16;

export const BASE_FONT_SIZE_DESKTOP = 14;

export const NATIVE_CALLBACK_URL = 'ckbk://ckbk.auth0.com/cordova/ckbk/callback';

// redux
export const CHAPTERS_GET_START = 'CHAPTERS_GET_START';
export const CHAPTERS_GET_SUCCESS = 'CHAPTERS_GET_SUCCESS';
export const CHAPTERS_GET_ERROR = 'CHAPTERS_GET_ERROR';

export const DIMENSIONS_SET = 'DIMENSIONS_SET';
export const ENTITIES_LIST_SET_SORTING_OPTIONS = 'ENTITIES_LIST_SET_SORTING_OPTIONS';

export const ACCESS_LOG_POST_START = 'ACCESS_LOG_POST_START';
export const ACCESS_LOG_POST_SUCCESS = 'ACCESS_LOG_POST_SUCCESS';
export const ACCESS_LOG_POST_ERROR = 'ACCESS_LOG_POST_ERROR';

export const ROUTE_TRANSITION_START = 'ROUTE_TRANSITION_START';
export const ROUTE_TRANSITION_END = 'ROUTE_TRANSITION_END';

export const SEARCH_IS_EMPTY = 'SEARCH_IS_EMPTY';
export const SEARCH_IS_ACTIVE = 'SEARCH_IS_ACTIVE';
export const SEARCH_IS_LOADING = 'SEARCH_IS_LOADING';
export const SEARCH_VIEW_REQUIRED = 'SEARCH_VIEW_REQUIRED';

export const SEARCH_EXAMPLES_GET_START = 'SEARCH_EXAMPLES_GET_START';
export const SEARCH_EXAMPLES_GET_SUCCESS = 'SEARCH_EXAMPLES_GET_SUCCESS';
export const SEARCH_EXAMPLES_GET_ERROR = 'SEARCH_EXAMPLES_GET_ERROR';

export const SIDEMENU_OPEN = 'SIDEMENU_OPEN';
export const SIDEMENU_CLOSE = 'SIDEMENU_CLOSE';

export const AVATAR_MENU_OPEN = 'AVATAR_MENU_OPEN';
export const AVATAR_MENU_CLOSE = 'AVATAR_MENU_CLOSE';

export const OVEN_MENU_OPEN = 'OVEN_MENU_OPEN';
export const OVEN_MENU_CLOSE = 'OVEN_MENU_CLOSE';

export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export const SET_ADVERT_VISIBILITY = 'SET_ADVERT_VISIBILITY';

export const SET_THEME = 'SET_THEME';
export const SET_CACHED_SYSTEM_TEXT_SIZE = 'SET_CACHED_SYSTEM_TEXT_SIZE';
export const SET_TEXT_SIZE = 'SET_TEXT_SIZE';
export const SET_USE_SYSTEM_TEXT_SIZE = 'SET_USE_SYSTEM_TEXT_SIZE';
export const LS_SYSTEM_TEXT_SIZE = 'LS_SYSTEM_TEXT_SIZE';
export const LS_USE_SYSTEM_TEXT_SIZE = 'LS_USE_SYSTEM_TEXT_SIZE';
export const LS_TEXT_SIZE = 'LS_TEXT_SIZE';
export const PAYWALL_CONFIGURE = 'PAYWALL_CONFIGURE';
export const CHECKOUT_EVENT = 'CHECKOUT_EVENT';
export const APPLY_COUPON = 'APPLY_COUPON';
export const LOAD_PRODUCT_PRICES_START = 'LOAD_PRODUCT_PRICES_START';
export const LOAD_PRODUCT_PRICES_SUCCESS = 'LOAD_PRODUCT_PRICES_SUCCESS';
// deprecated
export const APPLY_PRODUCT_START = 'APPLY_PRODUCT_START';
// deprecated
export const APPLY_PRODUCT_SUCCESS = 'APPLY_PRODUCT_SUCCESS';

export const TABLE_OF_CONTENTS_OPEN = 'TABLE_OF_CONTENTS_OPEN';
export const TABLE_OF_CONTENTS_PRINT_CLOSE = 'TABLE_OF_CONTENTS_PRINT_CLOSE';
export const TABLE_OF_CONTENTS_CLOSE = 'TABLE_OF_CONTENTS_CLOSE';
export const TABLE_OF_CONTENTS_SET_SELECTED = 'TABLE_OF_CONTENTS_SET_SELECTED';

export const USER_LOGIN_STATUS = 'USER_LOGIN_STATUS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SUBSCRIPTION_STATUS = 'USER_SUBSCRIPTION_STATUS';

export const USER_REFRESH_START = 'USER_REFRESH_START';
export const USER_REFRESH_SUCCESS = 'USER_REFRESH_SUCCESS';
export const USER_REFRESH_ERROR = 'USER_REFRESH_ERROR';
export const USER_UPDATE_INFO = 'USER_UPDATE_INFO';

export const USER_CHANGE_SUBSCRIPTION_PLAN_START = 'USER_CHANGE_SUBSCRIPTION_PLAN_START';
export const USER_CHANGE_SUBSCRIPTION_PLAN_SUCCESS = 'USER_CHANGE_SUBSCRIPTION_PLAN_SUCCESS';
export const USER_CHANGE_SUBSCRIPTION_PLAN_ERROR = 'USER_CHANGE_SUBSCRIPTION_PLAN_ERROR';

export const USER_CLAIM_VOUCHER_START = 'USER_CLAIM_VOUCHER_START';
export const USER_CLAIM_VOUCHER_SUCCESS = 'USER_CLAIM_VOUCHER_SUCCESS';
export const USER_CLAIM_VOUCHER_ERROR = 'USER_CLAIM_VOUCHER_ERROR';
export const USER_CLAIM_VOUCHER_CHECKOUT = 'USER_CLAIM_VOUCHER_CHECKOUT';

// user collection start
export const USER_COLLECTION_TEMPORARY = 'USER_COLLECTION_TEMPORARY';

export const USER_COLLECTION_ADD_START = 'USER_COLLECTION_ADD_START';
export const USER_COLLECTION_ADD_SUCCESS = 'USER_COLLECTION_ADD_SUCCESS';
export const USER_COLLECTION_ADD_ERROR = 'USER_COLLECTION_ADD_ERROR';

export const USER_COLLECTION_ADD_ITEM_TO_START = 'USER_COLLECTION_ADD_ITEM_TO_START';
export const USER_COLLECTION_ADD_ITEM_TO_SUCCESS = 'USER_COLLECTION_ADD_ITEM_TO_SUCCESS';
export const USER_COLLECTION_ADD_ITEM_TO_ERROR = 'USER_COLLECTION_ADD_ITEM_TO_ERROR';

export const USER_COLLECTION_REMOVE_ITEM_START = 'USER_COLLECTION_REMOVE_ITEM_START';
export const USER_COLLECTION_REMOVE_ITEM_SUCCESS = 'USER_COLLECTION_REMOVE_ITEM_SUCCESS';
export const USER_COLLECTION_REMOVE_ITEM_ERROR = 'USER_COLLECTION_REMOVE_ITEM_ERROR';

export const USER_COLLECTION_DELETE_START = 'USER_COLLECTION_DELETE_START';
export const USER_COLLECTION_DELETE_SUCCESS = 'USER_COLLECTION_DELETE_SUCCESS';
export const USER_COLLECTION_DELETE_ERROR = 'USER_COLLECTION_DELETE_ERROR';

export const USER_COLLECTION_UPDATE_START = 'USER_COLLECTION_UPDATE_START';
export const USER_COLLECTION_UPDATE_SUCCESS = 'USER_COLLECTION_UPDATE_SUCCESS';
export const USER_COLLECTION_UPDATE_ERROR = 'USER_COLLECTION_UPDATE_ERROR';

export const USER_COLLECTION_GET_ITEMS_START = 'USER_COLLECTION_GET_ITEMS_START';
export const USER_COLLECTION_GET_ITEMS_SUCCESS = 'USER_COLLECTION_GET_ITEMS_SUCCESS';
export const USER_COLLECTION_GET_ITEMS_ERROR = 'USER_COLLECTION_GET_ITEMS_ERROR';

export const USER_COLLECTION_MARK_ITEM = 'USER_COLLECTION_MARK_ITEM';

export const USER_COLLECTIONS_GET_START = 'USER_COLLECTIONS_GET_START';
export const USER_COLLECTIONS_GET_SUCCESS = 'USER_COLLECTIONS_GET_SUCCESS';
export const USER_COLLECTIONS_GET_ERROR = 'USER_COLLECTIONS_GET_ERROR';

export const USER_COLLECTION_GET_START = 'USER_COLLECTION_GET_START';
export const USER_COLLECTION_GET_SUCCESS = 'USER_COLLECTION_GET_SUCCESS';

export const USER_POSITIVE_FEEDBACK_GET_SUCCESS = 'USER_POSITIVE_FEEDBACK_GET_SUCCESS';

export const USER_INFO_UPDATE_SUCCESS = 'USER_INFO_UPDATE_SUCCESS';

export const USER_INFO_UPDATE_ERROR = 'USER_INFO_UPDATE_ERROR';

export const USER_INFO_UPDATE_START = 'USER_INFO_UPDATE_START';

export const USER_COLLECTION_GET_ERROR = 'USER_COLLECTION_GET_ERROR';

export const USER_SETTINGS_CHANGE_START = 'USER_SETTINGS_CHANGE_START';
export const USER_SETTINGS_CHANGE_SUCCESS = 'USER_SETTINGS_CHANGE_SUCCESS';
export const USER_SETTINGS_CHANGE_ERROR = 'USER_SETTINGS_CHANGE_ERROR';
export const USER_SETTINGS_CHANGE_LOCAL = 'USER_SETTINGS_CHANGE_LOCAL';

export const USER_COOKIE_CONSENT = 'USER_COOKIE_CONSENT';

// user collection end
export const LOGIN_TOKEN_EXPIRED = 'LOGIN_TOKEN_EXPIRED';

export const FAVOURITES_GET_START = 'FAVOURITES_GET_START';
export const FAVOURITES_GET_SUCCESS = 'FAVOURITES_GET_SUCCESS';
export const FAVOURITES_GET_ERROR = 'FAVOURITES_GET_ERROR';
export const ADD_FAVOURITE_CONTENT = 'ADD_FAVOURITE_CONTENT';
export const REMOVE_FAVOURITE_CONTENT = 'REMOVE_FAVOURITE_CONTENT';
export const FAVOURITE_CONTENT_SAVE_ERROR = 'FAVOURITE_CONTENT_SAVE_ERROR';

export const AUTH_USER = 'AUTH_USER';
export const AUTH_IN_PROGRESS = 'AUTH_IN_PROGRESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_IN_PROGRESS = 'FORGOT_IN_PROGRESS';
export const AUTH_CLEARDOWN = 'AUTH_CLEARDOWN';
export const SOCIAL_AUTH_IN_PROGRESS = 'SOCIAL_AUTH_IN_PROGRESS';
export const SOCIAL_AUTH_COMPLETE = 'SOCIAL_AUTH_COMPLETE';
export const SOCIAL_AUTH_CANCEL = 'SOCIAL_AUTH_CANCEL';

export const SIGNUP_BY_EMAIL_IN_PROGRESS = 'SIGNUP_BY_EMAIL_IN_PROGRESS';
export const SIGNUP_BY_EMAIL = 'SIGNUP_BY_EMAIL';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const FEATURE_USED = 'FEATURE_USED';
export const FEATURE_ENABLED = 'FEATURE_ENABLED';

// Home connect
export const APPLIANCE_ADJUSTMENT_START = 'APPLIANCE_ADJUSTMENT_START';
export const APPLIANCE_ADJUSTMENT_SUCCESS = 'APPLIANCE_ADJUSTMENT_SUCCESS';
export const APPLIANCE_ADJUSTMENT_ERROR = 'APPLIANCE_ADJUSTMENT_ERROR';

export const GET_APPLIANCES_START = 'GET_APPLIANCES_START';
export const GET_APPLIANCES_SUCCESS = 'GET_APPLIANCES_SUCCESS';
export const GET_APPLIANCES_ERROR = 'GET_APPLIANCES_ERROR';

export const GET_APPLIANCE_STATUS_START = 'GET_APPLIANCE_STATUS_START';
export const GET_APPLIANCE_STATUS_SUCCESS = 'GET_APPLIANCE_STATUS_SUCCESS';
export const GET_APPLIANCE_STATUS_ERROR = 'GET_APPLIANCE_STATUS_ERROR';

export const GET_APPLIANCE_PROGRAMS_START = 'GET_APPLIANCE_PROGRAMS_START';
export const GET_APPLIANCE_PROGRAMS_ERROR = 'GET_APPLIANCE_PROGRAMS_ERROR';
export const GET_APPLIANCE_PROGRAMS_SUCCESS = 'GET_APPLIANCE_PROGRAMS_SUCCESS';

export const STOP_APPLIANCE_START = 'STOP_APPLIANCE_START';
export const STOP_APPLIANCE_SUCCESS = 'STOP_APPLIANCE_SUCCESS';
export const STOP_APPLIANCE_ERROR = 'STOP_APPLIANCE_ERROR';

export const SET_PRIMARY_OVEN = 'SET_PRIMARY_OVEN';

export const HOME_CONNECT_DISCONNECT = 'HOME_CONNECT_DISCONNECT';
export const HOME_CONNECT_RESET = 'HOME_CONNECT_RESET';

export const HOME_CONNECT_EVENT_CHANNEL_CONNECTING = 'HOME_CONNECT_EVENT_CHANNEL_CONNECTING';
export const HOME_CONNECT_EVENT_CHANNEL_OPEN = 'HOME_CONNECT_EVENT_CHANNEL_OPEN';
export const HOME_CONNECT_EVENT_CHANNEL_CLOSE = 'HOME_CONNECT_EVENT_CHANNEL_CLOSE';
export const HOME_CONNECT_NOTIFICATION = 'HOME_CONNECT_NOTIFICATION';
export const HOME_CONNECT_EVENT_CHANNEL_ERROR = 'HOME_CONNECT_EVENT_CHANNEL_ERROR';
export const HOME_CONNECT_SET_RECIPE = 'HOME_CONNECT_SET_RECIPE';
export const HOME_CONNECT_CLEAR_RECIPE = 'HOME_CONNECT_CLEAR_RECIPE';
export const HOME_CONNECT_NEXT_STEP = 'HOME_CONNECT_NEXT_STEP';
export const HOME_CONNECT_SET_CURRENT_STEP = 'HOME_CONNECT_SET_CURRENT_STEP';

export const HOME_CONNECT_PROMO_CLAIM_START = 'HOME_CONNECT_PROMO_CLAIM_START';
export const HOME_CONNECT_PROMO_CLAIM_ERROR = 'HOME_CONNECT_PROMO_CLAIM_ERROR';
export const HOME_CONNECT_PROMO_CLAIM_ALLOWED = 'HOME_CONNECT_PROMO_CLAIM_ALLOWED';
export const HOME_CONNECT_PROMO_CLAIM_DENIED = 'HOME_CONNECT_PROMO_CLAIM_DENIED';

export const PROMO_BANNER_HIDE = 'PROMO_BANNER_HIDE';
export const PROMO_BANNER_DISABLE = 'PROMO_BANNER_DISABLE';
export const PROMO_BANNER_ENABLE = 'PROMO_BANNER_ENABLE';

export const SHOW_METERED_ACCESS_BANNER = 'SHOW_METERED_ACCESS_BANNER';
export const HIDE_METERED_ACCESS_BANNER = 'HIDE_METERED_ACCESS_BANNER';

export const CONFIGURE_NAVIGATION = 'CONFIGURE_NAVIGATION';

export const DEVICE_CONNECTION_STATE_CHANGE = 'DEVICE_CONNECTION_STATE_CHANGE';
export const DEVICE_SET_INFO = 'DEVICE_SET_INFO';

export const IN_APP_PAYMENT_INIT = 'IN_APP_PAYMENT_INIT';
export const IN_APP_PAYMENT_READY = 'IN_APP_PAYMENT_READY';
export const IN_APP_PAYMENT_ERROR = 'IN_APP_PAYMENT_ERROR';
export const IN_APP_PAYMENT_PRODUCT_UPDATED = 'IN_APP_PAYMENT_PRODUCT_UPDATED';
export const IN_APP_PAYMENT_PURCHASE_START = 'IN_APP_PAYMENT_PURCHASE_START';
export const IN_APP_PAYMENT_PURCHASE_ERROR = 'IN_APP_PAYMENT_PURCHASE_ERROR';
export const IN_APP_PAYMENT_PURCHASE_COMPLETE = 'IN_APP_PAYMENT_PURCHASE_COMPLETE';
export const IN_APP_PAYMENT_PURCHASE_CANCELLED = 'IN_APP_PAYMENT_PURCHASE_CANCELLED';
export const IN_APP_PAYMENT_RESTORE_START = 'IN_APP_PAYMENT_RESTORE_START';
export const IN_APP_PAYMENT_RESTORE_COMPLETE = 'IN_APP_PAYMENT_RESTORE_COMPLETE';
export const IN_APP_PAYMENT_RESTORE_ERROR = 'IN_APP_PAYMENT_RESTORE_ERROR';
export const IN_APP_PAYMENT_CLEAR_ERRORS = 'IN_APP_PAYMENT_CLEAR_ERRORS';

export const COLLECTION_SORT_OPTIONS = [
  { key: 'name', label: 'Alphabetical', direction: 'asc' },
  { key: 'available_from', label: 'Recently used', direction: 'desc' },
  { key: 'created_at', label: 'Recently created', direction: 'desc' },
];

export const LS_FIRST_LAUNCH_DATE = 'FIRST_LAUNCH_DATE';

export const LS_RATING_DIALOG_STATUS = 'RATING_DIALOG_STATUS';

export const LS_LAST_TIME_POPUP_SHOWN = 'LAST_TIME_POPUP_SHOWN';

export const ANDROID_APP_ID = 'app.ckbk.com';

export const IOS_APP_ID = '1476621703';

export const IOS_APP_STORE_URL = `https://apps.apple.com/app/ckbk/id${IOS_APP_ID}?mt=8&action=write-review`;

export const CONVERSION_SET_INITIAL_DATA = 'CONVERSION_SET_INITIAL_DATA';
export const CONVERSION_SET_CONVERTED_INGREDIENTS = 'CONVERSION_SET_CONVERTED_INGREDIENTS';
export const CONVERSION_SWITCH_CURRENT_MEASUREMENT_SYSTEM =
  'CONVERSION_SWITCH_CURRENT_MEASUREMENT_SYSTEM';
export const CONVERSION_TOGGLE_EDIT_OPEN = 'CONVERSION_TOGGLE_EDIT_OPEN';
export const CONVERSION_DELETE = 'CONVERSION_DELETE';

export const MAX_IMG_FILE_SIZE = 10485760;

export const MIN_IMG_FILE_SIZE = 0;

export const SUPPORTED_IMG_FORMATS = ['jpg', 'jpeg', 'png'];

export const acceptedFormatsInASentence = (acceptedFormats = SUPPORTED_IMG_FORMATS) =>
  `${acceptedFormats
    .slice(0, -1)
    .join(', ')
    .toUpperCase()} or ${acceptedFormats[acceptedFormats.length - 1].toUpperCase()}`;
