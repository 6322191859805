/* eslint-disable import/prefer-default-export */

import {
  TABLE_OF_CONTENTS_OPEN,
  TABLE_OF_CONTENTS_CLOSE,
  TABLE_OF_CONTENTS_SET_SELECTED,
  TABLE_OF_CONTENTS_PRINT_CLOSE,
} from '../constants';

export function openTableOfContents() {
  return dispatch => {
    dispatch({
      type: TABLE_OF_CONTENTS_OPEN,
    });
  };
}

export function closeTableOfContents() {
  return dispatch => {
    dispatch({
      type: TABLE_OF_CONTENTS_CLOSE,
    });
  };
}

export function closeTOCBeforePrinting(isPrintTriggeredFromUI = false) {
  return dispatch => {
    dispatch({
      type: TABLE_OF_CONTENTS_PRINT_CLOSE,
      payload: {
        isPrintTriggeredFromUI,
      },
    });
  };
}

export function setSelectedTableOfContents(selected) {
  if (selected?.book?.isbn) {
    return dispatch => {
      dispatch({
        type: TABLE_OF_CONTENTS_SET_SELECTED,
        payload: {
          selected,
        },
      });
    };
  }
  return () => {};
}
