import React, { useEffect, useRef, useState, Suspense, useCallback, useMemo } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import anime from 'animejs';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider, createTheme } from '@material-ui/core/styles';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useQuery, useMutation } from '@apollo/client';

import { setDimensions } from 'actions/dimensions';
import { closeTOCBeforePrinting, openTableOfContents } from 'actions/tableOfContents';
import {
  BREAKPOINTS,
  LS_TEXT_SIZE,
  LS_SYSTEM_TEXT_SIZE,
  LS_USE_SYSTEM_TEXT_SIZE,
  BASE_FONT_SIZE,
  BASE_FONT_SIZE_DESKTOP,
  LS_FIRST_LAUNCH_DATE,
  LS_LAST_TIME_POPUP_SHOWN,
  LS_RATING_DIALOG_STATUS,
} from 'constants/index';
import { FIRST_TIME_LAUNCHED_ANDROID, FIRST_TIME_LAUNCHED_IOS } from 'constants/eventTypes';
import WebLayout from 'components/Layout/WebLayout';
import LayoutProps from 'components/Layout/LayoutProps';
import {
  GET_POSITIVE_FEEDBACK_COUNT,
  LOG_RATE_POPUP_EVENTS,
  GET_RATE_POPUP_EVENTS,
} from 'components/Recipe/PopupLogQueries';

import TableOfContentsProps from 'components/TableOfContents/TableOfContentsProps';
import createCkbkTheme from './create-ckbk-theme';
import getSystemTheme from '../../api/helpers/getSystemTheme';
import adjustFontSizes from '../../api/helpers/adjustFontSizes';
import { setTextSize } from '../../actions/textSize';
import { setTextSizeUseSystem } from '../../actions/textSizeUseSystem';
import getSystemFontZoom from '../../api/helpers/getSystemFontZoom';
import switchToSystemPreferredFontZoom from '../../api/helpers/switchToSystemPreferredFontZoom';
import { FONT_SCALING_ARRAY } from '../../constants/view';
import { setCachedSystemTextSize } from '../../actions/cachedSystemTextSize';
import { setPositiveFeedbackCount } from '../../actions/positiveFeedbackCount';

const AppLayout = React.lazy(() =>
  import(/* webpackChunkName: 'app-layout' */ 'components/Layout/AppLayout'),
);

// TODO: Move this variable to ENV or somewhere more automatic
const APP_VERSION = 'APP_VERSION';

const LazyAppLayout = props => (
  <Suspense fallback={<div />}>
    <AppLayout {...props} />
  </Suspense>
);

const Layout = props => {
  useStyles();
  const isOnDesktop = useMediaQuery('(min-width: 641px)');
  const osPrefersDark = useMediaQuery('(prefers-color-scheme: dark)');
  const user = useSelector(state => state.user);
  const theme = useSelector(state => state.config.theme);
  const textSize = useSelector(state => state.config.textSize);
  const cachedSystemTextSize = useSelector(state => state.config.cachedSystemTextSize);
  const textSizeUseSystem = useSelector(state => state.config.textSizeUseSystem);
  const [prefersDarkMode, setPrefersDarkMode] = useState(
    theme === 'dark' || (theme === 'system' && osPrefersDark),
  );
  /**
   * We're caching the state of toc.isOpen, because we got an updateLayout useEffect down
   * that's relying on its state, to prevent unnecessary re-renders
   * */
  const [cachedTOCState, setCachedTOCState] = useState(false);

  const dispatch = useDispatch();

  const platform = useSelector(state => state.config.platform);
  const dimensions = useSelector(state => state.dimensions);
  const tableOfContents = useSelector(state => state.tableOfContents);
  const platformId = useMemo(
    () => (typeof window !== 'undefined' && window.cordova ? window.cordova.platformId : 'web'),
    [],
  );

  const layoutPanelRef = useRef(null);

  const userID = user && user.id ? user.id : -1;

  const [logRatePopupEvents, { data: popupEventData, error: popupMutationError }] = useMutation(
    LOG_RATE_POPUP_EVENTS,
    {
      refetchQueries: [
        {
          query: GET_RATE_POPUP_EVENTS,
          variables: { user_id: userID },
        },
      ],
    },
  );

  const {
    loading: ratePopupEventsLoading,
    error: ratePopupEventsError,
    data: ratePopupEventsData,
  } = useQuery(GET_RATE_POPUP_EVENTS, {
    variables: { user_id: userID },
    skip: userID === -1,
  });

  const { firstLaunchDate, fullLog, lastInvite, firstLaunchDateLocal } = useMemo(
    () => {
      const response = {
        firstLaunchDate: null,
        fullLog: [],
        lastInvite: null,
        firstLaunchDateLocal: null,
      };

      if (
        !['ios', 'android'].includes(platformId) ||
        ratePopupEventsLoading ||
        !ratePopupEventsData
      ) {
        if (ratePopupEventsError) {
          console.error(ratePopupEventsError);
        }

        return response;
      }

      const {
        firstLaunchIOS,
        firstLaunchAndroid,
        lastGooglePlayReviewInvite,
        lastAppStoreReviewInvite,
        fullLog,
      } = ratePopupEventsData.GetRatePopupEvents;

      response.fullLog = fullLog;
      response.firstLaunchDate = platformId === 'ios' ? firstLaunchIOS : firstLaunchAndroid;
      response.lastInvite =
        platformId === 'ios' ? lastAppStoreReviewInvite : lastGooglePlayReviewInvite;
      response.firstLaunchDateLocal = new Date().toISOString();

      return response;
    },
    [platformId, ratePopupEventsData, ratePopupEventsLoading, ratePopupEventsError],
  );

  const { loading: feedbackCountLoading, data: feedbackCountData } = useQuery(
    GET_POSITIVE_FEEDBACK_COUNT,
    {
      variables: {
        user_id: user && user.id ? `${user.id}` : null,
      },
    },
  );

  /*
  * useEffect to mark first launch date, and initialize ls variables related to rate us on store popup
  * */
  useEffect(
    () => {
      if (!user || user.id <= 0 || !['ios', 'android'].includes(platformId)) {
        return;
      }

      if (!localStorage.getItem(LS_FIRST_LAUNCH_DATE) && firstLaunchDateLocal) {
        if (firstLaunchDate) {
          localStorage.setItem(LS_FIRST_LAUNCH_DATE, firstLaunchDate);
        } else {
          const eventType =
            platformId === 'ios' ? FIRST_TIME_LAUNCHED_IOS : FIRST_TIME_LAUNCHED_ANDROID;

          localStorage.setItem(LS_FIRST_LAUNCH_DATE, firstLaunchDateLocal);
          logRatePopupEvents({
            variables: { user_id: user.id, event_type: eventType },
          });
        }
      }

      if (lastInvite) {
        localStorage.setItem(LS_LAST_TIME_POPUP_SHOWN, lastInvite);
      }

      if (fullLog) {
        localStorage.setItem(LS_RATING_DIALOG_STATUS, JSON.stringify(fullLog));
      }
    },
    [firstLaunchDate, fullLog, lastInvite, user, platformId, firstLaunchDateLocal],
  );

  useEffect(
    () => {
      if (
        !feedbackCountLoading &&
        feedbackCountData &&
        feedbackCountData.UserPositiveFeedbackCount !== undefined
      ) {
        dispatch(setPositiveFeedbackCount(feedbackCountData.UserPositiveFeedbackCount));
      }
    },
    [feedbackCountLoading, feedbackCountData, dispatch],
  );

  const onSetPanelRef = panel => {
    layoutPanelRef.current = panel;
  };

  const documentBodyWidth = typeof document !== 'undefined' ? document.body.clientWidth : 0;

  /**
   * We wrote this function to adjust the scaling minimum to the 0.85 limit and keep the seven steps design
   * Which is matching Android's max down-scaling limit, which mean it's the smallest visible limit
   * */
  const readjustSmallScale = scale => {
    if (scale === 0.85) {
      return 0.95;
    }

    if (scale === 0.7) {
      return 0.9;
    }

    if (scale === 0.55) {
      return 0.85;
    }

    return scale;
  };

  useEffect(
    () => {
      setPrefersDarkMode(theme === 'dark' || (theme === 'system' && osPrefersDark));
    },
    [theme, osPrefersDark],
  );

  useEffect(() => {
    // Since we don't have attributes on the server side for the those states,
    // in order to persist them we are using localStorage
    // since we can't run localStorage on the server side this is
    // the only logical place we can do this in
    const savedTextSize = localStorage.getItem(LS_TEXT_SIZE)
      ? JSON.parse(localStorage.getItem(LS_TEXT_SIZE))
      : 1;
    const savedTextSizeUseSystem = localStorage.getItem(LS_USE_SYSTEM_TEXT_SIZE)
      ? JSON.parse(localStorage.getItem(LS_USE_SYSTEM_TEXT_SIZE))
      : true;
    const savedCachedSystemTextSize = localStorage.getItem(LS_SYSTEM_TEXT_SIZE)
      ? JSON.parse(localStorage.getItem(LS_SYSTEM_TEXT_SIZE))
      : 1;

    if (savedCachedSystemTextSize !== 1) {
      dispatch(setCachedSystemTextSize(savedCachedSystemTextSize));
    }

    if (savedTextSize !== 1) {
      dispatch(setTextSize(savedTextSize));
    }

    if (!savedTextSizeUseSystem) {
      document.documentElement.style.fontSize = '16px';
      dispatch(setTextSizeUseSystem(savedTextSizeUseSystem));
    }

    // Updating app version in the doc.appVersion attribute
    document.documentElement.setAttribute('app-version', APP_VERSION);
  }, []);

  const getClosestNumberWithinLimits = size =>
    FONT_SCALING_ARRAY().reduce(
      (prev, curr) => (Math.abs(curr - size) < Math.abs(prev - size) ? curr : prev),
    );

  const syncFontSize = useCallback(
    async () => {
      if (textSizeUseSystem && localStorage.getItem(LS_USE_SYSTEM_TEXT_SIZE) === 'true') {
        await switchToSystemPreferredFontZoom();

        const systemFontScale = await getSystemFontZoom();

        /**
         * In iOS case we can have values set in a wierd way thanks to Apple's genius designs and UX :)
         *
         * So we need to convert the fetched value to the closest number in our scaling limits.
         * */

        const closestNumberWithinLimits = getClosestNumberWithinLimits(systemFontScale);

        dispatch(setCachedSystemTextSize(systemFontScale));
        dispatch(setTextSize(closestNumberWithinLimits));
      }

      if (!textSizeUseSystem) {
        await switchToSystemPreferredFontZoom(false);

        const usedSize =
          cachedSystemTextSize !== 1 ? cachedSystemTextSize : readjustSmallScale(textSize);

        adjustFontSizes(usedSize, isOnDesktop);
      }
    },
    [textSize, textSizeUseSystem, isOnDesktop],
  );

  useEffect(
    () => {
      const runSyncFontSize = () => {
        syncFontSize().then();
      };

      if (typeof window !== 'undefined') {
        runSyncFontSize();
        window.addEventListener('focus', runSyncFontSize);
      }

      return () => {
        if (typeof window !== 'undefined') {
          window.removeEventListener('focus', runSyncFontSize);
        }
      };
    },
    [syncFontSize],
  );

  useEffect(
    () => {
      const isAppPlatformWithSystemTheme = platform === 'app' && theme === 'system';

      if (isAppPlatformWithSystemTheme) {
        getSystemTheme().then(systemTheme => {
          document.documentElement.setAttribute('data-theme', systemTheme);
          setPrefersDarkMode(systemTheme === 'dark');
        });
      }
    },
    [platform, theme],
  );

  useEffect(
    () => {
      const handleResize = debounce(event => {
        const previousDimensions = dimensions;
        const dimensionInfo = {};
        const bodyWidth = documentBodyWidth;

        if (window.cordova || event?.type === 'resize') {
          // The displayType is decided by the server based on the user-agent.
          // We usually don't want the client to override this because it causes stuff
          // on the screen to jump around during the client side render.
          // However, for cordova the app is rendered to a static file and the value of displayType is always set to the default.
          // We will set a more appropriate value here.
          // We will also alter the displayType if it is a genuine resize event (type === 'resize')
          if (bodyWidth < BREAKPOINTS.TABLET_VERTICAL) {
            dimensionInfo.displayType = 'mobile';
          } else if (bodyWidth < BREAKPOINTS.DESKTOP) {
            dimensionInfo.displayType = 'tablet';
          } else {
            dimensionInfo.displayType = 'desktop';
          }
        }
        // iOS Safari often sends resize events while scolling because the appearance of the URL bar
        // triggers a height change. We only really care for width changes.

        if (
          previousDimensions.body !== bodyWidth ||
          previousDimensions.displayType !== dimensionInfo.displayType
        ) {
          dispatch(
            setDimensions({
              body: bodyWidth,
              'layout.panel': layoutPanelRef.current
                ? layoutPanelRef.current.clientWidth
                : bodyWidth,
              ...dimensionInfo,
            }),
          );
        }
      }, 250);

      if (typeof window !== 'undefined') {
        handleResize();
        window.addEventListener('resize', handleResize);
      }

      return () => {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleResize);
        }
      };
    },
    [documentBodyWidth],
  );

  /**
   * Manipulate the extra space on the right side of the layout.
   * @param  {Number} space
   */
  const changeExtraSpaceTo = space => {
    anime({
      targets: layoutPanelRef.current,
      duration: 225,
      easing: 'easeOutSine',
      paddingRight: space,
    });
  };

  /**
   * Repaints application layout. Creates / Reduce / Remove extra space for Table of Contents.
   */
  const updateLayout = () => {
    const sideEmptySpace =
      (dimensions.body -
        (layoutPanelRef.current ? layoutPanelRef.current.clientWidth : documentBodyWidth)) /
      2;
    const animateBy = dimensions.tableOfContents - sideEmptySpace;

    if (dimensions.body < BREAKPOINTS.DESKTOP) {
      changeExtraSpaceTo(0);
    } else if (tableOfContents.isOpen) {
      if (animateBy > 0) {
        changeExtraSpaceTo(animateBy);
      } else {
        changeExtraSpaceTo(0);
      }
    } else {
      changeExtraSpaceTo(0);
    }
  };

  useEffect(
    () => {
      if (tableOfContents.isOpen === cachedTOCState) {
        return;
      }

      setCachedTOCState(tableOfContents.isOpen);
      updateLayout();
    },
    [tableOfContents.isOpen, dimensions.body, props.type],
  );

  /**
   * This one is meant to automatically open/close the TOC on printing,
   * only when the printing is initiated using the Keyboard shortcut.
   * Hence the use of shared state isPrintTriggeredFromUI to indicate if the
   * action is fired from the UI or Keyboard shortcut (CTRL+P / CMD + P etc...)
   * */
  useEffect(
    () => {
      window.onbeforeprint = () => {
        const { isOpen, isPrintTriggeredFromUI } = tableOfContents;

        if (isOpen && !isPrintTriggeredFromUI) {
          dispatch(closeTOCBeforePrinting());
        }
      };

      window.onafterprint = () => {
        const { isOpen, isPrint, isPrintTriggeredFromUI } = tableOfContents;

        if (!isOpen && isPrint && !isPrintTriggeredFromUI) {
          dispatch(openTableOfContents());
        }
      };
    },
    [tableOfContents, dispatch],
  );

  let layout;
  if (platform === 'app') {
    layout = <props.LayoutComponent {...props} onSetPanelRef={onSetPanelRef} />;
  } else {
    layout = <WebLayout {...props} onSetPanelRef={onSetPanelRef} />;
  }

  const muiTheme = React.useMemo(
    () => {
      const baseFont = isOnDesktop ? BASE_FONT_SIZE_DESKTOP : BASE_FONT_SIZE;
      let newTextSize = baseFont;

      if (textSize !== 0 && !textSizeUseSystem) {
        const usedSize = cachedSystemTextSize > 1 ? cachedSystemTextSize : textSize;

        const recalculated = readjustSmallScale(usedSize);
        newTextSize =
          recalculated > 0 ? baseFont * recalculated : baseFont / Math.abs(recalculated);
      }

      return createTheme(createCkbkTheme(prefersDarkMode, newTextSize));
    },
    [prefersDarkMode, textSizeUseSystem, textSize, isOnDesktop],
  );

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        {layout}
      </ThemeProvider>
    </StylesProvider>
  );
};

Layout.propTypes = {
  type: PropTypes.oneOf(['normal', 'wide', 'full', 'bare']),
  dimensions: LayoutProps.dimensions,
  platform: PropTypes.string,
  tableOfContents: TableOfContentsProps,
  LayoutComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

Layout.defaultProps = {
  platform: 'web',
  type: 'normal',
  dimensions: {},
  tableOfContents: {
    isOpen: false,
    isPrint: false,
  },
  LayoutComponent: LazyAppLayout,
};

export default Layout;
